import {
  SolarGraphNewLinear,
  SolarSignpost2Linear,
  SolarDocumentTextLinear,
  SolarHandMoneyLinear,
  SolarUploadLinear,
  SolarUserIdLinear,
} from "@/assets/icons";
import { useIsDemo } from "@/hooks";
import { RouteEnum } from "@/types";
import { NavLink } from "react-router-dom";
import { NavButton } from ".";
import { useGetMe } from "@/hooks/queries";
import { cn } from "@/lib/utils";

const navLinks = (isDemo: boolean, isAdmin: boolean) => [
  {
    to: `${RouteEnum.UPLOADDATA}${isDemo ? "?demo=true" : ""}`,
    tLabelKey: "common_dataUpload",
    Icon: SolarUploadLinear,
    isVisible: true,
    className: "jr-nav-pg-upload-data",
  },
  {
    to: `${RouteEnum.DASHBOARD}${isDemo ? "?demo=true" : ""}`,
    tLabelKey: "common_dashboard",
    Icon: SolarGraphNewLinear,
    isVisible: true,
    className: "jr-nav-pg-dashboard",
  },
  {
    to: `${RouteEnum.SUPPLY_CHAIN}${isDemo ? "?demo=true" : ""}`,
    tLabelKey: "common_supplyChain",
    Icon: SolarSignpost2Linear,
    isVisible: true,
    className: "jr-nav-pg-supply-chain",
  },
  {
    to: `${RouteEnum.REPORTING}${isDemo ? "?demo=true" : ""}`,
    tLabelKey: "common_reportCenter",
    Icon: SolarDocumentTextLinear,
    isVisible: true,
    className: "jr-nav-pg-reporting",
  },
  {
    to: `${RouteEnum.RECOMMENDATIONS}?transportMode=all&type=all${
      isDemo ? "&demo=true" : ""
    }`,
    tLabelKey: "common_recommendations",
    Icon: SolarHandMoneyLinear,
    isVisible: true,
    className: "jr-nav-pg-recommendations",
  },
  {
    // to: `${RouteEnum.ADMIN_DASHBOARD}${isDemo ? "?demo=true" : ""}`,
    to: RouteEnum.ADMIN_DASHBOARD,
    tLabelKey: "common_adminDashboard",
    Icon: SolarUserIdLinear,
    isVisible: isAdmin,
  },
];

export function NavItems({
  isExpanded,
  closeMenu,
}: {
  isExpanded: boolean;
  closeMenu?: () => void;
}) {
  const { data: { isAdmin = false } = {} } = useGetMe({ enabled: true });
  const isDemo = useIsDemo();
  return navLinks(isDemo, isAdmin)
    .filter(({ isVisible }) => isVisible)
    .map(({ to, tLabelKey, Icon, className }) => (
      <NavLink
        to={to}
        key={to}
        className={cn("w-fit", className)}
        onClick={closeMenu}
      >
        {({ isActive }) => (
          <NavButton {...{ isActive, isExpanded, Icon, tLabelKey }} />
        )}
      </NavLink>
    ));
}
