import { CompletenessStatus, ProcessingStatus, Status } from "@/hooks/queries";
import { type Socket } from "socket.io-client";

export type SocketProviderContextType = {
  socket: Socket | null;
};

export enum SocketEvent {
  SUBSCRIBE_STATUS_UPDATES = "subscribeToDataProcessingStatusUpdates",
  PROCESSING_STATUS_UPDATE = "fileProcessingStatusUpdate",
  DELETE_STATUS_UPDATE = "fileDeleteStatusUpdate",
  REPORT_PROCESSING_STATUS_UPDATE = "reportProcessingStatusUpdate",
}

export type FileStatusUpdateEvent = {
  id: number; //848,
  userId: string; //203,
  name: string; //"SMA_25.xlsx",
  location: string; //"files/203/18-07-2024/shipments/input/848_22_18_51.xlsx",
  type: string; //"SHIPMENT",
  completenessStatus: CompletenessStatus; //"PENDING",
  processingStatus: ProcessingStatus; //"SHIPMENT_PARSING_SUCCESS",
  validRowsCount: number; //25,
  invalidRowsCount: number; //0,
  totalRowsCount: number; //25,
  successfulRowsCount: number; //25,
  hasReport?: boolean; //true
  reportFilePath: string; //"203/18-07-2024/shipments/initial_check/848_shipments_valid.csv",
  createdAt: string; //"2024-07-18T22:18:51.716Z",
  updatedAt: string; //"2024-07-18T22:19:20.435Z",
  status: Status | null; // "DELETION_SUCCESS"
};

export type ReportStatusUpdateEvent = {
  id: number;
  name: string;
  fileName: string;
  status: "PENDING" | "SUCCESS" | "FAILED";
  dateFrom: string;
  dateTo: string;
  createdAt: string;
};
