import { RouteEnum } from "@/types";
import { TooltipContent } from "./TooltipContent";
import { TooltipTitle } from "./TooltipTitle";
import { buttonVariants } from "@/components/ui";
import {
  ArrowRightIcon,
  ChevronDownIcon,
  QuestionMarkCircledIcon,
  Share2Icon,
} from "@radix-ui/react-icons";
import { cn } from "@/lib/utils";
import { Step } from "react-joyride";
import { Trans } from "react-i18next";
import { TFunction } from "i18next";
import clsx from "clsx";

// target property should be given a value in the format ".jr-<target>-pg-<page>"
// this class name should be given to the target element for the tooltip to appear

export const getPageSteps: (
  t: TFunction<"translation", undefined>,
) => Record<string, Step[]> = (t) => ({
  [RouteEnum.DASHBOARD]: [
    {
      target: ".jr-start-pg-dashboard",
      title: <TooltipTitle>{t("titles_jr-start-pg-dashboard")}</TooltipTitle>,
      content: (
        <TooltipContent>
          <Trans i18nKey="descriptions_jr-start-pg-dashboard">
            Welcome to NxtLog. This product tour will help you get started. It
            can be activated at any time by clicking the{" "}
            <span
              className={clsx(
                "mx-1",
                buttonVariants({ variant: "outline", size: "sm" }),
              )}
            >
              <QuestionMarkCircledIcon className="mr-2 h-5" />
              <span>Product tour</span>
            </span>{" "}
            button.
          </Trans>
          {/* {t("descriptions_jr-start-pg-dashboard")} */}
        </TooltipContent>
      ),
      disableBeacon: true,
    },
    {
      target: ".jr-nav-pg-dashboard",
      title: <TooltipTitle>{t("titles_jr-nav-pg-dashboard")}</TooltipTitle>,
      content: (
        <TooltipContent>
          <Trans i18nKey="descriptions_jr-nav-pg-dashboard">
            <span className="font-semibold">
              Welcome to Your Control Center:
            </span>{" "}
            Dive into the heart of NxtLog with our intuitive dashboard. Here,
            glance at the pivotal insights that matter most. A realm where
            simplicity meets efficiency, providing you with a snapshot of
            essential data, from sustainability metrics to operational
            milestones.
          </Trans>
        </TooltipContent>
      ),
      disableBeacon: true,
    },
    {
      target: ".jr-export-report-link-pg-dashboard",
      title: (
        <TooltipTitle>
          {t("titles_jr-export-report-link-pg-dashboard")}
        </TooltipTitle>
      ),
      content: (
        <TooltipContent>
          <Trans i18nKey="descriptions_jr-export-report-link-pg-dashboard">
            <span>
              No matter where you wander within NxtLog, the{" "}
              <span
                className={buttonVariants({ variant: "outline", size: "sm" })}
              >
                <Share2Icon className="mr-2 h-5" />
                <span>Export Report</span>
              </span>{" "}
              button is your constant companion. Seamlessly generate
              comprehensive sustainability reports, ensuring your data aligns
              perfectly with ISO14083 standards. A testament to our commitment
              to accuracy and your sustainability journey.
            </span>
          </Trans>
        </TooltipContent>
      ),
      disableBeacon: true,
    },
    {
      target: ".jr-time-selector-pg-dashboard",
      title: (
        <TooltipTitle>{t("titles_jr-time-selector-pg-dashboard")}</TooltipTitle>
      ),
      content: (
        <TooltipContent>
          {t("descriptions_jr-time-selector-pg-dashboard")}
        </TooltipContent>
      ),
      disableBeacon: true,
    },
  ],
  [RouteEnum.SUPPLY_CHAIN]: [
    {
      target: ".jr-nav-pg-supply-chain",
      title: <TooltipTitle>{t("titles_jr-nav-pg-supply-chain")}</TooltipTitle>,
      content: (
        <TooltipContent>
          <Trans i18nKey="descriptions_jr-nav-pg-supply-chain">
            <span className="font-semibold">Emissions Insights Unveiled:</span>{" "}
            Navigate through the Supply Chain Overview to pinpoint emission
            hotspots within your network. A first step towards impactful
            changes, spotlighting lanes that demand your attention and catalyze
            sustainability efforts.
          </Trans>
        </TooltipContent>
      ),
      disableBeacon: true,
    },
    {
      target: ".jr-details-pg-supply-chain",
      title: (
        <TooltipTitle>{t("titles_jr-details-pg-supply-chain")}</TooltipTitle>
      ),
      placement: "top-end",
      content: (
        <TooltipContent>
          <Trans i18nKey="descriptions_jr-details-pg-supply-chain">
            <span className="font-semibold">Granular Analysis:</span> Delve
            deeper into the details by clicking on the{" "}
            <span
              className={buttonVariants({ variant: "outline", size: "sm" })}
            >
              <span className="mr-2">See Details</span>
              <ChevronDownIcon className="h-4 w-4 text-primary" />
            </span>{" "}
            button. Understand the composition of your transport modes and
            identify the primary contributors to your carbon footprint.
            Knowledge is power, and we equip you with all you need to strategize
            effectively.
          </Trans>
        </TooltipContent>
      ),
      disableBeacon: true,
    },
    {
      target: ".jr-recommendations-pg-supply-chain",
      placement: "top-end",
      title: (
        <TooltipTitle>
          {t("titles_jr-recommendations-pg-supply-chain")}
        </TooltipTitle>
      ),
      content: (
        <TooltipContent>
          <Trans i18nKey="descriptions_jr-recommendations-pg-supply-chain">
            <span className="font-semibold">
              Forward-Thinking Recommendations:
            </span>{" "}
            You can click on the{" "}
            <span
              className={cn(
                buttonVariants({ variant: "outline", size: "sm" }),
                "whitespace-nowrap px-2",
              )}
            >
              <span className="mr-2">See Recommendations</span>
              <ArrowRightIcon className="h-4 w-4" />
            </span>{" "}
            button to venture into a world of potential with our Recommendations
            Overview. Currently broad, yet brimming with potential for
            personalized guidance, tailored to transform your supply chain into
            a beacon of efficiency and sustainability.
          </Trans>
        </TooltipContent>
      ),
      disableBeacon: true,
    },
  ],
  [RouteEnum.REPORTING]: [
    {
      target: ".jr-nav-pg-reporting",
      title: <TooltipTitle>{t("titles_jr-nav-pg-reporting")}</TooltipTitle>,
      content: (
        <TooltipContent>{t("descriptions_jr-nav-pg-reporting")}</TooltipContent>
      ),
      disableBeacon: true,
    },
    {
      target: ".jr-create-report-pg-reporting",
      title: (
        <TooltipTitle>{t("titles_jr-create-report-pg-reporting")}</TooltipTitle>
      ),
      content: (
        <TooltipContent>
          <Trans i18nKey="descriptions_jr-create-report-pg-reporting">
            <span className="font-semibold">
              Report Generation, Simplified:
            </span>{" "}
            Craft emission reports with the precision and flexibility you
            require. Select your timeframe, customize your report&apos;s title,
            and bring your data story to life. All at the touch of a button.
          </Trans>
        </TooltipContent>
      ),
      disableBeacon: true,
    },
  ],
  [RouteEnum.UPLOADDATA]: [
    {
      target: ".jr-nav-pg-upload-data",
      title: <TooltipTitle>{t("titles_jr-nav-pg-upload-data")}</TooltipTitle>,
      content: (
        <TooltipContent>
          <Trans i18nKey="descriptions_jr-nav-pg-upload-data">
            <span className="font-semibold">Seamless Data Integration:</span>{" "}
            Begin with uploading your shipment file, crafted to meet
            Nxtlog&apos;s precise standards. A foundation for the transformation
            of data into actionable insights.
          </Trans>
        </TooltipContent>
      ),
      disableBeacon: true,
    },
    {
      target: ".jr-overview-pg-upload-data",
      title: (
        <TooltipTitle>{t("titles_jr-overview-pg-upload-data")}</TooltipTitle>
      ),
      content: (
        <TooltipContent>
          <Trans i18nKey="descriptions_jr-overview-pg-upload-data">
            <span className="font-semibold">Transparency at Every Step:</span>{" "}
            Witness the journey of your data from upload to integration. Our
            Data Processing Overview offers a clear view of your data’s
            status—celebrating successful uploads and providing clarity on any
            hiccups along the way.
          </Trans>
        </TooltipContent>
      ),
      disableBeacon: true,
    },
    {
      target: ".jr-status-pg-upload-data",
      title: (
        <TooltipTitle>{t("titles_jr-status-pg-upload-data")}</TooltipTitle>
      ),
      content: (
        <TooltipContent>
          {t("descriptions_jr-status-pg-upload-data")}
        </TooltipContent>
      ),
      disableBeacon: true,
    },
    {
      target: ".jr-dowload-report-pg-upload-data",
      placement: "top-end",
      title: (
        <TooltipTitle>
          {t("titles_jr-dowload-report-pg-upload-data")}
        </TooltipTitle>
      ),
      content: (
        <TooltipContent>
          {t("descriptions_jr-dowload-report-pg-upload-data")}
        </TooltipContent>
      ),
      disableBeacon: true,
    },
  ],
  [RouteEnum.RECOMMENDATIONS]: [
    {
      target: ".jr-nav-pg-recommendations",
      title: (
        <TooltipTitle>{t("titles_jr-nav-pg-recommendations")}</TooltipTitle>
      ),
      content: (
        <TooltipContent>
          <Trans i18nKey="descriptions_jr-nav-pg-recommendations">
            <span className="font-semibold">Tailored to Your Network:</span>{" "}
            Discover recommendations curated for your unique supply chain.
            NxtLog&apos;s insights, drawn from a deep understanding of logistics
            and sustainability, aim to propel you towards efficiency and
            environmental stewardship.
          </Trans>
        </TooltipContent>
      ),
      disableBeacon: true,
    },
    {
      target: ".jr-filter-pg-recommendations",
      title: (
        <TooltipTitle>{t("titles_jr-filter-pg-recommendations")}</TooltipTitle>
      ),
      content: (
        <TooltipContent>
          {t("descriptions_jr-filter-pg-recommendations")}
        </TooltipContent>
      ),
      disableBeacon: true,
    },
    {
      target: ".jr-cards-pg-recommendations",
      title: (
        <TooltipTitle>{t("titles_jr-cards-pg-recommendations")}</TooltipTitle>
      ),
      placement: "top-start",
      content: (
        <TooltipContent>
          {t("descriptions_jr-cards-pg-recommendations")}
        </TooltipContent>
      ),
      disableBeacon: true,
    },
  ],
});
