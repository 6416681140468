import { type BlocksContent } from "@strapi/blocks-react-renderer";

export interface UserRecommendationsResponse {
  data: {
    id: number;
    attributes: {
      keycloakUserId: string;
      recommendations: RecommendationsListResponse;
    };
  }[];
}

export interface RecommendationsListResponse {
  data: RecommendationListItem[];
  meta: Meta;
}

export interface RecommendationsDetailsResponse {
  data: RecommendationDetailItem[];
  meta: Meta;
}

export interface RecommendationListItem {
  id: number;
  attributes: RecommendationListItemAttributes;
}
export interface RecommendationDetailItem {
  id: number;
  attributes: RecommendationDetailItemAttributes;
}

interface RecommendationDetailItemAttributes {
  title: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  overview: string;
  contentBlocks: BlockItem[];
  kpiMetrics: KpiMetrics;
  regions: Region[];
  transportModes: TransportMode[];
  image: ImageWrapper;
}

type RecommendationListItemAttributes = Omit<
  RecommendationDetailItemAttributes,
  "contentBlocks"
>;

interface BlockItem {
  id: number;
  contentBlock: BlocksContent;
}
interface TransportMode {
  id: number;
  transportMode: string;
}

interface Region {
  id: number;
  region: string;
}

export interface KpiMetrics {
  id: number;
  costSavings: number | null;
  emissionReduction: number | null;
  leadTimeImprovement: number | null;
}

interface ImageWrapper {
  data: ImageData;
}

interface ImageData {
  id: number;
  attributes: ImageAttributes;
}

export interface ImageAttributes {
  name: string;
  alternativeText: string | null;
  caption: string;
  width: number;
  height: number;
  formats: ImageFormats;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: string | null;
  provider: string;
  provider_metadata: string | null;
  createdAt: string;
  updatedAt: string;
  blurhash: string | null;
}

export interface ImageFormats {
  large?: ImageFormat;
  small?: ImageFormat;
  medium?: ImageFormat;
  thumbnail?: ImageFormat;
}

export interface ImageFormat {
  ext: string;
  url: string;
  hash: string;
  mime: string;
  name: string;
  path: string | null;
  size: number;
  width: number;
  height: number;
}

interface Meta {
  pagination: Pagination;
}

interface Pagination {
  page: number;
  pageSize: number;
  pageCount: number;
  total: number;
}

export interface PaginatedResponse<T> {
  totalCount: number;
  totalPages: number;
  data: T[];
}

export type DataProcessingOverviewResponse =
  PaginatedResponse<DataProcessingRecord>;
export interface DataProcessingRecord {
  id: number;
  hasReport: boolean;
  createdAt: string;
  name: string;
  completenessStatus: CompletenessStatus;
  processingStatus: ProcessingStatus;
  processedShipments?: number;
  totalShipments?: number;
  successfulShipments: number | null;
  processingReportFileName: string;
  status: Status | null;
}

export enum CompletenessStatus {
  Pending = "PENDING",
  Successful = "SUCCESSFUL",
  PartialSuccess = "PARTIALLY_SUCCESSFUL",
  Failed = "FAILED",
}

export enum ProcessingStatus {
  Pending = "PENDING",
  InitialCheckSuccess = "INITIAL_CHECK_SUCCESS",
  InitialCheckFailed = "INITIAL_CHECK_FAILED",
  ShipmentParsingSuccess = "SHIPMENT_PARSING_SUCCESS",
  ShipmentParsingFailed = "SHIPMENTS_PARSING_FAILED",
  EmissionCalculationSuccess = "EMISSION_CALCULATION_SUCCESS",
  EmissionCalculationFailed = "EMISSION_CALCULATION_FAILED",
  DataImportSuccess = "DATA_IMPORT_SUCCESS",
  DataImportFailed = "DATA_IMPORT_FAILED",
}

export interface LimitOffsetQueryParams {
  limit?: number;
  offset?: number;
}

export enum Status {
  DeletionInProgress = "DELETION_IN_PROGRESS",
  DeletionSuccess = "DELETION_SUCCESS",
  DeletionFailed = "DELETION_FAILED",
  ProcessingPaused = "PROCESSING_PAUSED",
  ProcessingPending = "PROCESSING_PENDING",
  ProcessingSuccess = "PROCESSING_SUCCESS",
  ProcessingFailed = "PROCESSING_FAILED",
}
