export enum RouteEnum {
  HOME = "/",
  DASHBOARD = "/dashboard",
  SUPPLY_CHAIN = "/supply-chain",
  REPORTING = "/reporting",
  RECOMMENDATIONS = "/recommendations",
  RECOMMENDATION = ":slug",
  UPLOADDATA = "/upload-data",
  TERMS_AND_CONDITIONS = "/terms-and-conditions",
  PRIVACY_POLICY = "/privacy-policy",
  IMPRINT = "/imprint",
  COOKIE_POLICY = "/cookie-policy",
  PROFILE = "/profile",
  INITIAL_UPLOAD = "/initial-upload",
  CREATE_ACCOUNT = "/create-account",
  ADMIN_DASHBOARD = "/admin-dashboard",
  DEV_SPACE = "/dev-space",
  UI_DESIGN = "/ui-design-elements",
}

export enum TimePeriodSelector {
  PrevWeek = "Previous week (Mon - Sun)",
  PrevCalendarMonth = "Previous calendar month",
  PrevQuarter = "Previous quarter",
  PrevYear = "Previous year",
  Prev5Years = "Previous 5 years",
  Last3Months = "Last 3 months",
  Last6Months = "Last 6 months",
  Last12Months = "Last 12 months",
  YearToDate = "Year to date",
}

export enum ReportingFrequency {
  Daily = "daily",
  Weekly = "weekly",
  Monthly = "monthly",
}

export enum ReportingStandards {
  ISO14083 = "ISO14083",
}

export enum ReportingType {
  Auto = "Auto reporting",
  Manual = "Manual reporting",
}

export enum ReportingTemplate {
  ISO14083 = "ISO14083",
  Template2 = "Template 2",
  Template3 = "Template 3",
  Template4 = "Template 4",
}

export enum RecommendationFilters {
  TransportMode = "transportMode",
  Region = "region",
  SortBy = "sortBy",
  Type = "type",
}
